<template>
  <div class="ut-d-flex navigation">
    <div>
      <ArrowLeftIcon
        @click="applyAction(SurveyAction.back)"
        v-if="showBackButton"
        data-e2e="back-button"
      />
    </div>
    <div>
      <ArrowRightIcon
        @click="applyAction(SurveyAction.next)"
        v-if="showNextButton"
        data-e2e="next-button"
      />
    </div>
  </div>
</template>

<script setup>
import { ArrowLeftIcon, ArrowRightIcon } from '@/features/ui';
import { SurveyAction } from '@/features/survey/types';
import { useSurvey } from '@/features/survey';

const { applyAction, showBackButton, showNextButton } = useSurvey();
</script>

<style lang="scss" scoped>
.navigation {
  position: absolute;
  top: 16px;
  right: 8px;
  left: 8px;
  justify-content: space-between;
  align-items: center;
}
</style>
